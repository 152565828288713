import '../styles/global.scss';

import type { AppPropsWithLayout } from '@types';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchInterval: 3600000,
            refetchIntervalInBackground: true,
          },
        },
      }),
  );

  const getLayout = Component.getLayout ?? ((page: unknown) => page);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>{getLayout(<Component {...pageProps} />)}</Hydrate>
      <ReactQueryDevtools />
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            borderRadius: '15px',
            fontWeight: 700,
          },
          success: {
            iconTheme: {
              primary: 'linear-gradient(145.56deg, #98EC2D 0.3%, #17AD37 94.23%)',
              secondary: 'white',
            },
          },
          error: {
            iconTheme: {
              primary: 'linear-gradient(138.01deg, #FF667C 0%, #EA0606 95.27%)',
              secondary: 'white',
            },
          },
        }}
      />
    </QueryClientProvider>
  );
}

export default MyApp;
